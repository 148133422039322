const date_suffix = (date: number) => {
  if (date == 1 || date == 21 || date == 31) {
    return 'st';
  } else if (date == 2 || date == 22) {
    return 'nd';
  } else if (date == 3 || date == 23) {
    return 'rd';
  } else {
    return 'th';
  }
};

export const time_since = (original: number | string | Date) => {
  original = new Date(original);

  let str = '';

  let months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  let chunks = [
    [31536000000, 'year'],
    [2592000000, 'month'],
    [604800000, 'week'],
    [86400000, 'day'],
    [3600000, 'hour'],
    [60000, 'minute'],
    [1000, 'second'],
  ];

  let today = new Date();
  let since = new Date(today.getTime() - original.getTime());

  if (since.getTime() > 604800000) {
    str =
      months[original.getMonth()] +
      ' ' +
      original.getDate() +
      date_suffix(original.getDate());

    if (since.getTime() > 31536000000) {
      str = str + ', ' + original.getFullYear();
    }

    return str;
  }

  let ms = 0;
  let name = 0;
  let i = 0;
  let ic = chunks.length;
  let count = 0;

  for (i = 0; i < ic; i++) {
    ms = chunks[i][0] as number;
    name = chunks[i][1] as number;

    count = Math.floor(since.getTime() / ms);

    if (count != 0) {
      break;
    }
  }

  return count + ' ' + name + (count == 1 ? '' : 's') + ' ago';
};
