import Link from 'next/link';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Bonus } from '../../types/Bonus';
import { Casino } from '../../types/Casino';
import { Review } from '../../types/Review';
import { BonusesCardSmall } from '../BonusesCard';
import { CasinosCardSmallForHomePage } from '../CasinosCard';
import { ReviewCardSmallHomePageWrapper } from '../ReviewCard';
import { WithLoading } from '../WithLoading';
import styles from './VerticalScoll.module.css';
interface IVerticalScollBock {
  entities: (Bonus | Casino | Review)[];
  entityType: 'bonus' | 'review' | 'casino';
  isLoading: boolean;
  title: React.ReactNode;
  link: string;
  visibleItemsInRow?: number;
}

const VerticalScollBock = ({
  visibleItemsInRow = 1,
  entityType,
  isLoading,
  entities,
  title,
  link,
}: IVerticalScollBock) => {
  const EnitityComponent = useMemo(() => {
    switch (entityType) {
      case 'casino':
        return CasinosCardSmallForHomePage;
      case 'bonus':
        return BonusesCardSmall;
      case 'review':
        return ReviewCardSmallHomePageWrapper;
      default:
        return <FormattedMessage defaultMessage="Wrong type" />;
    }
  }, [entityType]);

  if (!entities.length && !isLoading) {
    return <></>;
  }

  return (
    <>
      <div className={styles.spacer} />
      <div className={styles.blockWrapper}>
        <WithLoading isLoading={isLoading}>
          <div className={styles.blockHeader}>
            <span>{title}</span>
            <Link href={link}>
              <a className={styles.viewAll}>
                <FormattedMessage defaultMessage="View all" />
              </a>
            </Link>
          </div>
          <div className={styles.entitiesWrapper}>
            {entities.map((entity) => (
              <div
                key={entity.id}
                className={styles.enity}
                style={{
                  minWidth: `${90 / visibleItemsInRow}%`,
                  maxWidth: `${90 / visibleItemsInRow}%`,
                }}
              >
                {/* @ts-ignore */}
                <EnitityComponent {...entity} />
              </div>
            ))}
          </div>
        </WithLoading>
      </div>
    </>
  );
};

export { VerticalScollBock };
