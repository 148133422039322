import { Box, Typography } from '@mui/material';
import type { NextPage } from 'next';
import Image from 'next/image';
import React from 'react';
import Casino from '../assets/images/casino.png';
import { InputWithIcon } from '../components/InputWithIcon';
import SearchIcon from '../assets/icons/search_icon.svg';
import styles from './home.module.css';
import { FormattedMessage, useIntl } from 'react-intl';
import { VerticalScollBock } from '../components/VerticalScrollBlock';
import { useRouter } from 'next/router';
import { prefetchBonuses, useBonusList } from '../config/queries/bonus';
import { prefetchCasinos, useCasinosList } from '../config/queries/casinos';
import { prefetchReviews, useReviewList } from '../config/queries/review';
import { SEOFields } from '../components/SEOFields';
import { dehydrate, QueryClient } from 'react-query';
import { prefetchConfig } from '../config/queries';
import { getServerProps } from '../config/serverProps';

const Home: NextPage = (props: { host: string }) => {
  const router = useRouter();
  const { formatMessage } = useIntl();
  const { data: bonuses, isLoading: isLoadingBonuses } = useBonusList({
    languageCode: router.locale,
    tag: 'home',
    field: 'created_at',
    order: 'DESC',
  });

  const { data: casinos, isLoading: isLoadingCasinos } = useCasinosList({
    languageCode: router.locale,
    field: 'rate',
    order: 'DESC',
  });

  const { data: reviews, isLoading: isLoadingReviews } = useReviewList({
    languageCode: router.locale,
    field: 'created_at',
    order: 'DESC',
  });

  const handleSearchClick = (searchText: string) => {
    router.push({
      pathname: '/search-result',
      query: {
        search: searchText,
      },
    });
  };

  return (
    <div className={styles.container}>
      <SEOFields
        host={props.host}
        description={formatMessage({
          defaultMessage: 'Home page description',
        })}
        title={formatMessage({
          defaultMessage: 'Stake&Chips ',
        })}
      />
      <Box className={styles.homeWrapper}>
        <Typography component="span" className={styles.homeTitle}>
          <FormattedMessage defaultMessage="Explore. Read the truth. Play" />
        </Typography>
        <div className={styles.mainImage}>
          <Image
            src={Casino}
            unoptimized={true}
            placeholder="blur"
            alt="Big Casino Slot"
          />
        </div>
        <div className={styles.searchBar}>
          <InputWithIcon
            onEnterKey={handleSearchClick}
            label={formatMessage({
              defaultMessage: 'Search to find casino overviews you like',
            })}
            Icon={SearchIcon}
          />
        </div>
        <VerticalScollBock
          entities={bonuses?.data || []}
          isLoading={isLoadingBonuses}
          entityType="bonus"
          title={
            <span>
              <b>
                <FormattedMessage defaultMessage="Hot-hot-hot" />
              </b>
              &nbsp;
              <FormattedMessage defaultMessage=" bonuses 🔥" />
            </span>
          }
          link={'/bonuses'}
          visibleItemsInRow={2}
        />
        <VerticalScollBock
          entities={casinos?.data || []}
          isLoading={isLoadingCasinos}
          entityType="casino"
          title={
            <span>
              <b>
                <FormattedMessage defaultMessage="Hottest" />
              </b>
              &nbsp;
              <FormattedMessage defaultMessage=" casinos 🔥" />
            </span>
          }
          link={'/casinos'}
        />

        <VerticalScollBock
          entities={casinos?.data || []}
          isLoading={isLoadingCasinos}
          entityType="casino"
          title={
            <span>
              <b>
                <FormattedMessage defaultMessage="Latest" />
              </b>
              &nbsp;
              <FormattedMessage defaultMessage=" casinos 🔥" />
            </span>
          }
          link={'/casinos'}
        />
        <VerticalScollBock
          entities={reviews?.data || []}
          isLoading={isLoadingReviews}
          entityType="review"
          title={
            <span>
              <b>
                <FormattedMessage defaultMessage="Recent reviews" />
              </b>
              &nbsp;
              <FormattedMessage defaultMessage="from members" />
            </span>
          }
          link={'/reviews'}
        />
      </Box>
    </div>
  );
};

Home.getInitialProps = async ({ req, res, locale }) => {
  if (!req) {
    return {};
  }
  const queryClient = new QueryClient();
  await Promise.all([
    getServerProps({ res, req, queryClient }),
    prefetchConfig(queryClient),
    prefetchCasinos(queryClient, {
      languageCode: locale,
      field: 'rate',
      order: 'DESC',
    }),
    prefetchBonuses(queryClient, {
      languageCode: locale,
      tag: 'home',
      field: 'created_at',
      order: 'DESC',
    }),
    prefetchReviews(queryClient, {
      languageCode: locale,
      field: 'created_at',
      order: 'DESC',
    }),
  ]);
  return {
    host: req.headers.host || null,
    dehydratedState: dehydrate(queryClient),
  };
};
export default Home;
