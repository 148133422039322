import clsx from 'clsx';
import Link from 'next/link';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Raiting, RaitSize } from '../Raiting';
import BottomIcon from '../../assets/icons/bottom_icon.svg';
import TopIcon from '../../assets/icons/top_icon.svg';
import styles from './ReviewSmall.module.css';
import { time_since } from '../../utils/time';
import { Review } from '../../types/Review';
import { CasinoTx } from '../../types/Casino';
import { useRouter } from 'next/router';
import { get } from 'lodash';

const maxLength = 150;

const ReviewCardSmall = ({
  review,
  withCasinoLink,
  raitSize = RaitSize.xs,
}: {
  raitSize?: RaitSize;
  withCasinoLink?: boolean;
  review: Review;
}) => {
  const { locale } = useRouter();
  const [readMore, changeReadMore] = useState(review.review.length > maxLength);
  const timeSince = time_since(review.createdAt);
  const handleClickReadMore = () => changeReadMore(!readMore);
  let shortText = review.review.slice(0, maxLength);
  if (review.review.length > maxLength) {
    shortText += '...';
  }

  const profileCustomImage = review.user.name
    ?.split(' ')
    .map((userString) => userString.charAt(0))
    .join('');

  const casinoEn = get(review, ['casinoReview', 'translations', 'en']);
  const casinoLocale: CasinoTx = get(
    review,
    ['casinoReview', 'translations', locale],
    casinoEn,
  );

  return (
    <div className={styles.review}>
      <div className={styles.reviewHeader}>
        <div className={styles.reviewHeaderLeft}>
          {/* TBD: user */}
          {/* {review.image ? (
            <Image src={review.image} width={32} height={32} />
          ) : ( */}
          <div className={styles.profileCustomImage}>{profileCustomImage}</div>
          {/* )} */}
          <div className={styles.reviewMainInfo}>
            <span className={clsx(styles.bold)}>{review.user.name}</span>
            <span className={clsx(styles.light)}>{timeSince}</span>
          </div>
        </div>
        <div className={styles.raiting}>
          {withCasinoLink && casinoLocale && (
            <Link href={`/casinos/${casinoLocale.slug}`}>
              <a className={clsx(styles.bold, styles.casinoName)}>
                {casinoLocale?.name}
              </a>
            </Link>
          )}
          <Raiting revert currentRaiting={review.rate} size={raitSize} />
        </div>
      </div>
      <div className={styles.reviewText}>
        {readMore ? shortText : review.review}
      </div>
      {review.review.length > maxLength && (
        <div className={styles.more} onClick={handleClickReadMore}>
          {readMore ? (
            <FormattedMessage defaultMessage="Read less" />
          ) : (
            <FormattedMessage defaultMessage="Read more" />
          )}
          {readMore ? <TopIcon /> : <BottomIcon />}
        </div>
      )}
      {withCasinoLink && casinoLocale && (
        <Link href={`/casinos/${casinoLocale.slug}?tab=review`}>
          <a className={styles.otherReviews}>
            <FormattedMessage
              defaultMessage="Other {casino} reviews"
              values={{ casino: casinoLocale?.name }}
            />
          </a>
        </Link>
      )}
    </div>
  );
};

const ReviewCardSmallHomePageWrapper = (review: Review) => {
  return (
    <div style={{ height: 'calc(100% - 16px) ' }}>
      <ReviewCardSmall review={review} withCasinoLink raitSize={RaitSize.xs} />
    </div>
  );
};

export { ReviewCardSmall, ReviewCardSmallHomePageWrapper };
