import { omit } from 'lodash';
import { stringify } from 'querystring';
import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
} from 'react-query';
import { CasinoTx } from '../../types/Casino';
import { ListResponse } from '../../types/common';
import { Review, ReviewPost, ReviewRespose } from '../../types/Review';
import { JockerApi } from '../api';

const normalizeReviewList = (reviews: ReviewRespose[]): Review[] =>
  reviews.map((review) => ({
    ...review,
    casinoReview: {
      ...review.casinoReview,
      translations: review.casinoReview.translations.reduce(
        (acc, translation) => ({
          ...acc,
          [translation.languageCode]: translation,
        }),
        {} as Record<string, CasinoTx>,
      ),
    },
  }));

const defaultPaginationParams = {
  perPage: 10,
};

const fetchReviewsList = async (
  { isLoadMore, ...props }: Record<string, string | string[] | boolean>,
  pageParam: number,
) => {
  const params = {
    ...defaultPaginationParams,
    ...props,
    currentPage: isLoadMore ? pageParam + 1 : props.currentPage,
  };
  const { data } = await JockerApi.get<ListResponse<ReviewRespose>>(
    `reviews/public-list?${stringify(params)}`,
  );

  const normalizedList = normalizeReviewList(data.data);
  return { ...data, data: normalizedList } as ListResponse<Review>;
};

export const useReviewListInfinity = (
  params: Record<string, string | string[] | boolean>,
  opt?: any,
) =>
  useInfiniteQuery(
    ['reviews-list-infinity', omit(params, 'isLoadMore')],
    ({ pageParam = 0 }) => fetchReviewsList(params, pageParam),
    {
      staleTime: Infinity,
      getPreviousPageParam: (firstPage) =>
        firstPage?.pagination.currentPage ?? undefined,
      getNextPageParam: (lastPage) =>
        lastPage?.pagination.currentPage ?? undefined,
      ...opt,
    },
  );

export const useReviewList = (
  params: Record<string, string | string[] | boolean>,
) =>
  useQuery(
    ['reviews-list', params],
    ({ pageParam = 0 }) => fetchReviewsList(params, pageParam),
    {},
  );

const postReview = async ({ token, ...form }: ReviewPost) => {
  const { data } = await JockerApi.post<ReviewRespose>(`reviews`, form, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return { ...data } as ReviewRespose;
};

export const usePostReview = (
  options: UseMutationOptions<ReviewRespose, Error, ReviewPost>,
) => useMutation<ReviewRespose, Error, ReviewPost>(postReview, options);

// SSR - prefetch

export const prefetchReviews = async (
  queryClient,
  params: Record<string, string | string[] | boolean>,
) => {
  await queryClient.prefetchQuery({
    queryKey: ['reviews-list', params],
    queryFn: () => fetchReviewsList(params, 0),
  });
};

export const prefetchReviewsInfinity = async (
  queryClient,
  params: Record<string, string | string[] | boolean>,
) => {
  await queryClient.prefetchInfiniteQuery({
    queryKey: ['reviews-list-infinity', omit(params, 'isLoadMore')],
    queryFn: () => fetchReviewsList(params, 0),
  });
};
